import React from "react"
import { useKeycloak } from "@react-keycloak/web"
import { LoadingIndicator } from "component-library"
import { subscribeToWebsocket } from "omlox-api-client"
import { useEffect, useState } from "react"
import { ProviderStore, readEnvironment } from "shared"
import { AvailabilityApi, Configuration } from "tenant-service-api-client"
import { Configuration as UserManagementConfiguration, DefaultApi } from "user-management-service-api-client"

/**
 * Lazy loads extension modules based on a request to the Service Avilability
 * Service that is currently mocked using the extensionModules.txt file
 *
 * @param {{ children: any }} props React components
 * @returns
 */
export const ProviderStoreProvider = (props: { children: any }) => {
	const [isLoadingModules, setLoadingModules] = useState(true)
	const [loadingTotal, setLoadingTotal] = useState<number | null>(null)
	const [loadingLeft, setLoadingLeft] = useState<number | null>(null)
	const [refresh, setRefresh] = useState(0)
	const { keycloak } = useKeycloak()

	useEffect(() => {
		let librariesToLoad = 0

		/**
		 * Checks if all modules have been loaded
		 *
		 */
		const checkFinishedLoading = () => {
			librariesToLoad--
			setLoadingLeft(librariesToLoad)
			if (librariesToLoad <= 0) {
				setLoadingModules(false)
			}
		}

		const permissionApi = new DefaultApi(
			new UserManagementConfiguration({
				basePath: `https://${readEnvironment("FQDN")}${readEnvironment(
					"REACT_APP_BACKEND_PATH_TENANT_SERVICE",
				)}/user-management`,
			}),
		)

		permissionApi.getSelf().then(user => {
			ProviderStore.user = user
		})

		permissionApi.getUserPermissions().then(permissions => {
			ProviderStore.userPermissions = permissions.flatMap(p => p.scopes.flatMap(s => p.resource + ":" + s))

			// To be replaced with Tenant Service call
			const availabilityApi = new AvailabilityApi(
				new Configuration({
					basePath: `https://${readEnvironment("FQDN")}${readEnvironment("REACT_APP_BACKEND_PATH_TENANT_SERVICE")}`,
				}),
			)

			// Load extension modules and providers
			availabilityApi.getAvailability().then(availability => {
				console.log("Enabled modules and services", availability)
				const extensionModules = availability
					.filter(availability => availability.availability !== false)
					.map(availability => availability.id)
				if (extensionModules.includes("location-provider-module")) {
					librariesToLoad++
					import(/* webpackChunkName: "location-provider-module" */ "location-provider-module").then(
						checkFinishedLoading,
					)
				}
				if (extensionModules.includes("geofence-module")) {
					librariesToLoad++
					import(/* webpackChunkName: "geofence-module" */ "geofence-module").then(checkFinishedLoading)
				}
				if (extensionModules.includes("trackable-service")) {
					librariesToLoad++
					import(/* webpackChunkName: "trackable-module" */ "trackable-module").then(checkFinishedLoading)
				}
				if (extensionModules.includes("map-module")) {
					librariesToLoad++
					import(/* webpackChunkName: "map-module" */ "map-module").then(checkFinishedLoading)
				}
				if (extensionModules.includes("event-history-service")) {
					librariesToLoad++
					import(/* webpackChunkName: "location-provider-history-module" */ "location-provider-history-module").then(
						checkFinishedLoading,
					)
				}
				if (extensionModules.includes("maps-service")) {
					librariesToLoad++
					import(/* webpackChunkName: "local-maps-module" */ "local-maps-module").then(checkFinishedLoading)
				}
				if (extensionModules.includes("ubirch-module")) {
					librariesToLoad++
					import(/* webpackChunkName: "ubirch-module" */ "ubirch-module").then(checkFinishedLoading)
				}
				if (extensionModules.includes("event-history-service")) {
					librariesToLoad++
					import(/* webpackChunkName: "fence-history-module" */ "fence-history-module").then(checkFinishedLoading)
				}
				if (extensionModules.includes("ordering-module")) {
					librariesToLoad++
					import(/* webpackChunkName: "ordering-module" */ "ordering-module").then(checkFinishedLoading)
				}
				if (extensionModules.includes("sap-module")) {
					librariesToLoad++
					import(/* webpackChunkName: "sap-module" */ "sap-module").then(checkFinishedLoading)
				}
				if (extensionModules.includes("device-management-module")) {
					librariesToLoad++
					import(/* webpackChunkName: "device-management-module" */ "device-management-module").then(
						checkFinishedLoading,
					)
				}
				if (extensionModules.includes("event-subscription-module") && extensionModules.includes("trackable-service")) {
					librariesToLoad++
					import(/* webpackChunkName: "event-subscription-module" */ "event-subscription-module").then(
						checkFinishedLoading,
					)
				}
				if (extensionModules.includes("user-management-module")) {
					librariesToLoad++
					import(/* webpackChunkName: "user-management-module" */ "user-management-module").then(checkFinishedLoading)
				}
				setLoadingTotal(librariesToLoad)
				setLoadingLeft(librariesToLoad)
				if (librariesToLoad <= 0) {
					setLoadingModules(false)
				}
			})
		})

		//Websocket events
		subscribeToWebsocket(keycloak)

		return ProviderStore.registerEventConsumer({
			eventId: "service-availability-changed",
			uniqueId: "provider-store-service-availability-changed",
			consumer: () => {
				setRefresh(refresh + 1)
			},
		})
	}, [refresh])

	if (isLoadingModules) {
		if (loadingLeft === null || loadingTotal === null) {
			return <LoadingIndicator position="center" />
		} else {
			return (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100vh",
						flexDirection: "column",
					}}
				>
					<LoadingIndicator />
					<br />
					<div>
						Loaded {loadingTotal - loadingLeft} / {loadingTotal} modules
					</div>
				</div>
			)
		}
	}

	return props.children
}
